import React from "react";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import contentStyles from "../../styles/Content.module.css";

const Error = () => {
  return (
    <div className={contentStyles.emptyResults}>
      <SentimentVeryDissatisfiedIcon
        style={{ fontSize: "100px", color: "#e23325", paddingTop: "10px" }}
      />
      <div className={contentStyles.wrongText}>Something went wrong</div>
      <p>Please refresh the page or try again later</p>
    </div>
  );
};

export default Error;
