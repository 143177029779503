import React, { useContext, useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Link from "next/link";
import { useMsal } from "@azure/msal-react";
import useDebounce from "../hooks/useDebounce";
import SearchCustomBox from "../components/Search";
import contentStyles from "../styles/Content.module.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import Loading from "../components/Loading";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import SearchDropdown from "../components/SearchDropdown";
import { CheckContext } from "../hooks/useContext";
import {
  algoliaIndexSelect,
  getDate,
  segmentEventClick,
  truncateDesc,
} from "../helpers";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import useWindowSize from "../hooks/useResize";
import * as Sentry from "@sentry/nextjs";
import gtag from "ga-gtag";
import Error from "../components/Error";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Snackbar from "@mui/material/Snackbar";
import navStyles from "../styles/Nav.module.css";

//reference to change color of pagination
const theme = createTheme({
  palette: {
    primary: {
      main: "#466AC7",
    },
  },
});

const algoliaEnvId =
  process.env.NEXT_PUBLIC_ENV === "DEV"
    ? process.env.NEXT_PUBLIC_ALGOLIA_ID
    : process.env.NEXT_PUBLIC_ALGOLIA_ID_PROD;

const algoliaEnvSec =
  process.env.NEXT_PUBLIC_ENV === "DEV"
    ? process.env.NEXT_PUBLIC_ALGOLIA_SECRET
    : process.env.NEXT_PUBLIC_ALGOLIA_SECRET_PROD;

const searchClient = algoliasearch(algoliaEnvId, algoliaEnvSec);

const Home = () => {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  const [deleteRecordLoading, setDeleteRecordLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [checked, setChecked] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteRecords, setDeleteRecords] = useState(false);
  const searchText = useDebounce(searchInput);
  const { algoliaIndex } = useContext(CheckContext);
  const { accounts } = useMsal();
  const resize = useWindowSize();

  useEffect(() => {
    if (searchText !== "") {
      window.analytics.track("Newsletter Archive Searched", {
        query: searchText,
      });
    }
  }, [searchText]);

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "Talent Brief Home | ATD",
      page_location: window.location.origin,
      page_path: window.location.pathname,
    });

    searchClient.clearCache();
    const index = searchClient.initIndex(algoliaIndexSelect(algoliaIndex));

    index
      .search(searchText, {
        clickAnalytics: true,
        enablePersonalization: true,
        hitsPerPage: 10,
        page: currentPage,
      })
      .then((hit) => (setContent(hit), setLoading(false)))
      .catch((err) => {
        Sentry.captureException(err);
        setLoading(false);
      });
  }, [searchText, currentPage, algoliaIndexSelect(algoliaIndex), checked]);

  const changePageNumber = (event) => {
    setChecked({});
    if (event.currentTarget.attributes[3].nodeValue === "Go to previous page") {
      setCurrentPage(currentPage - 1);
    } else if (
      event.currentTarget.attributes[3].nodeValue === "Go to next page"
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(event.currentTarget.textContent - 1);
    }
  };

  const getName = (name) => {
    if (accounts.length !== 0) {
      if (name.indexOf(".") !== -1) {
        return resize <= 360
          ? truncateDesc(name.substring(name, name.indexOf(".")), 11)
          : resize <= 380
          ? truncateDesc(name.substring(name, name.indexOf(".")), 17)
          : resize <= 450
          ? truncateDesc(name.substring(name, name.indexOf(".")), 22)
          : name.substring(name, name.indexOf("."));
      } else {
        return resize <= 450 ? truncateDesc(name, 24) : name;
      }
    } else {
      if (name.indexOf(".") !== -1) {
        return resize <= 320
          ? truncateDesc(name.substring(name, name.indexOf(".")), 17)
          : resize <= 420
          ? truncateDesc(name.substring(name, name.indexOf(".")), 24)
          : name.substring(name, name.indexOf("."));
      } else {
        return resize <= 420 ? truncateDesc(name, 24) : name;
      }
    }
  };

  const campaignChecked = (event, id) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [id]: event.target.checked,
    }));
  };

  const mapRecordsToDelete = () => {
    let idsToDelete = [];

    for (let key in checked) {
      if (checked[key]) {
        idsToDelete.push(key);
      }
    }

    return idsToDelete;
  };

  const deleteCampaignChecked = () => {
    let idsForDeletion = mapRecordsToDelete();

    setOpen(true);
    setDeleteRecords(true);
    setDeleteRecordLoading(true);

    const requestOptions = {
      ids: idsForDeletion,
    };

    axios
      .post("/api/deleteCampaignDetail", requestOptions)
      .then((res) =>
        setTimeout(() => {
          setDeleteRecordLoading(false);
          setChecked({});
        }, 3000)
      )
      .catch((err) => {
        setDeleteRecordLoading(false);
        Sentry.captureException(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={contentStyles.bodyContainer}>
        <div className={contentStyles.searchFlexContainer}>
          <InstantSearch
            searchClient={searchClient}
            indexName={algoliaIndexSelect(algoliaIndex)}
          >
            <SearchCustomBox
              searchInputState={{
                setSearchInput,
                searchInput,
                setCurrentPage,
                setChecked,
              }}
            />
          </InstantSearch>
          <SearchDropdown />
        </div>
        {loading ? (
          <Loading />
        ) : content && !loading ? (
          <div>
            {content.hits.map((item, index) => (
              <div
                key={item.objectID}
                className={contentStyles.checkboxAndLinkContainer}
              >
                {accounts.length === 0 ? null : (
                  <div>
                    <Checkbox
                      size="small"
                      color="primary"
                      disabled={deleteRecordLoading}
                      onChange={(event) =>
                        campaignChecked(event, item.objectID)
                      }
                    />
                  </div>
                )}
                <div className={contentStyles.accordionContainer}>
                  <Link
                    href={{
                      pathname: `/details/${encodeURIComponent(
                        getName(item.title).replaceAll(" ", "-")
                      ).toLowerCase()}`,
                      query: {
                        id: item.objectID,
                      },
                    }}
                  >
                    <a
                      onClick={() =>
                        segmentEventClick(
                          item.objectID,
                          index,
                          getName(item.title)
                            .replaceAll(" ", "-")
                            .toLowerCase(),
                          content.queryID,
                          currentPage,
                          content.query
                        )
                      }
                    >
                      <div
                        className={
                          index === 0
                            ? contentStyles.accordionSummaryFirstChild
                            : contentStyles.accordionSummary
                        }
                      >
                        <div className={contentStyles.accordianImageDetailFlex}>
                          <div>
                            <LibraryAddCheckIcon
                              style={{ fontSize: "13px", color: "#e23325" }}
                            />
                          </div>
                          <div>&nbsp;&nbsp;</div>
                          <div>{getName(item.title)}</div>
                        </div>
                        <div>{getDate(item.title)}</div>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            ))}
            {mapRecordsToDelete().length !== 0 ? (
              <div style={{ paddingTop: "20px", textAlign: "center" }}>
                <button
                  className={contentStyles.deleteButton}
                  onClick={deleteCampaignChecked}
                >
                  Delete Records
                </button>
              </div>
            ) : null}
            {content.nbPages ? (
              <Stack sx={{ paddingTop: "20px" }}>
                <Pagination
                  sx={{ margin: "0 auto" }}
                  count={content.nbPages}
                  size="medium"
                  color="primary"
                  onChange={changePageNumber}
                  page={currentPage + 1}
                />
              </Stack>
            ) : (
              <div>
                <p className={contentStyles.emptyResults}>
                  There are no search results that match{" "}
                  <span style={{ fontWeight: "600" }}>"{searchText}"</span>
                  <br />
                  Please try a new search.
                </p>
              </div>
            )}
          </div>
        ) : (
          <Error />
        )}
      </div>
      {deleteRecords ? (
        deleteRecordLoading ? (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
          >
            <div className={navStyles.taskProgress}>
              <div className={navStyles.taskText}>
                {mapRecordsToDelete().length} record deletions in progress
              </div>
              <div>&nbsp;</div>
              <div className={navStyles.loader}>
                <CircularProgress color="warning" size="2rem" />
              </div>
            </div>
          </Snackbar>
        ) : (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            onClose={() => setOpen(false)}
          >
            <div className={navStyles.taskProgressComplete}>
              <div className={navStyles.taskText}>
                Records deletion complete
              </div>
              <div>&nbsp;</div>
              <div>
                <CheckCircleIcon
                  sx={{ paddingTop: "6px", paddingLeft: "6px" }}
                  fontSize="large"
                />
              </div>
            </div>
          </Snackbar>
        )
      ) : null}
    </ThemeProvider>
  );
};

export default Home;
