import gtag from "ga-gtag";

export const imgResize = (src, size) => {
  if (size !== undefined) {
    return `${src
      .replace("https://d22bbllmj4tvv8.cloudfront.net", "https://atd.imgix.net")
      .replace(
        "http://files.astd.org.s3.amazonaws.com",
        "https://atd-s3.imgix.net"
      )
      .replace(
        "https://atd-brightspot-lower.s3.amazonaws.com",
        "https://atd-brightspot-lower.imgix.net"
      )}?w=${size.w}&h=${size.h}&fit=crop`;
  }

  return src
    .replace("https://d22bbllmj4tvv8.cloudfront.net", "https://atd.imgix.net")
    .replace(
      "http://files.astd.org.s3.amazonaws.com",
      "https://atd-s3.imgix.net"
    )
    .replace(
      "https://atd-brightspot-lower.s3.amazonaws.com",
      "https://atd-brightspot-lower.imgix.net"
      //)}?${resize}&fit=crop`
    );
};

export const shareButtons = (platform, link) => {
  const encodedLink = encodeURIComponent(link);
  const openLink = (socialLink) => {
    if (socialLink) {
      window.open(socialLink, "_blank");
    }
  };

  switch (platform) {
    case "facebook":
      link = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
      openLink(link);
      break;

    case "twitter":
      link = `https://twitter.com/intent/tweet?url=${encodedLink}`;
      openLink(link);
      break;

    case "linkedin":
      link = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`;
      openLink(link);
      break;

    case "copy":
      navigator.clipboard.writeText(link);
      break;

    default:
      break;
  }
};

export const algoliaIndexSelect = (agIndex) => {
  switch (agIndex) {
    case "Talent Brief":
      return "talentBrief-newsletter";
    default:
      return agIndex;
  }
};

export const truncateDesc = (text, length) => {
  if (text.length <= length) {
    return text;
  } else {
    return text.substring(0, length) + "...";
  }
};

export const segmentEventClick = async (
  objectId,
  indexPosition,
  titleClicked,
  queryId,
  curPage,
  userSearch
) => {
  let positionIndex;
  if (curPage !== 0) {
    positionIndex = parseInt(indexPosition) + 10;
  } else {
    positionIndex = parseInt(indexPosition);
  }
  await window.analytics.track("Newsletter Archive Clicked", {
    objectName: titleClicked,
    objectID: objectId,
    index: "talentBrief-newsletter",
    queryID: queryId,
    position: positionIndex,
    source: "talentBrief",
    page: curPage + 1,
    query: userSearch,
  });
  //await googleAnalyticsEvent(titleClicked, objectId);
};

// const googleAnalyticsEvent = async (title, id) => {
//   gtag("event", "product_clicked", {
//     action: `User clicked ${title} - ${id}`,
//   });
// };

export const getDate = (date) => {
  if (date.indexOf(".") !== -1) {
    const modifyDate = date
      .substring(date.indexOf(".") + 1, date.length)
      .split("-");
    let finalDate;

    switch (parseInt(modifyDate[0])) {
      case 1:
        finalDate = "Jan";
        break;
      case 2:
        finalDate = "Feb";
        break;
      case 3:
        finalDate = "Mar";
        break;
      case 4:
        finalDate = "Apr";
        break;
      case 5:
        finalDate = "May";
        break;
      case 6:
        finalDate = "Jun";
        break;
      case 7:
        finalDate = "Jul";
        break;
      case 8:
        finalDate = "Aug";
        break;
      case 9:
        finalDate = "Sep";
        break;
      case 10:
        finalDate = "Oct";
        break;
      case 11:
        finalDate = "Nov";
        break;
      case 12:
        finalDate = "Dec";
        break;
      default:
        finalDate = "Month";
    }
    const removeFirstDateSequence = modifyDate.splice(1);
    const dateYear = removeFirstDateSequence.join(" 20");
    return `${finalDate}, ${dateYear}`;
  } else {
    return "-";
  }
};
