import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { connectSearchBox } from "react-instantsearch-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import searchStyles from "../../styles/Search.module.css";
import SearchDropdown from "../SearchDropdown";

const SearchCustomBox = ({ searchInputState }) => {
  const [indexType, setIndexType] = useState("Talent Brief");
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleChange = (event) => {
    searchInputState.setSearchInput(
      event.currentTarget.value.replace(/[^a-zA-Z0-9\s]/g, "")
    );
    searchInputState.setCurrentPage(0);
    searchInputState.setChecked({})
  };

  return (
    <div>
      <div className={searchStyles.searchContainer}>
        <div>
          <input
            className={searchStyles.searchInput}
            placeholder="Search archives"
            type="text"
            value={searchInputState.searchInput}
            onChange={handleChange}
          />
        </div>
        <div className={searchStyles.searchIcon}>
          <SearchIcon />
        </div>
        {/* <div
          className={searchStyles.searchDropdownFlex}
          onClick={() => setOpenDrawer(true)}
        >
          <div style={{ paddingLeft: "6px" }}>{indexType}</div>
          <div>&nbsp;</div>
          <div style={{ paddingTop: "4px" }}>
            {openDrawer ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        </div> */}
      </div>
      {/* <SearchDropdown
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        setIndex={setIndexType}
      /> */}
    </div>
  );
};

export default connectSearchBox(SearchCustomBox);
