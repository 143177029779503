import React, { useContext } from "react";
import searchDropdownStyles from "../../styles/Search.module.css";
import newsletterOptions from "../../constants/newsletterOptions";
import { CheckContext } from "../../hooks/useContext";

const SearchDropdown = ({ isOpen, setIndex, setIsOpen }) => {
  const { setAlgoliaIndex } = useContext(CheckContext);
  return isOpen ? (
    <div
      className={searchDropdownStyles.searchDropdownBlur}
      onClick={() => setIsOpen(false)}
    >
      <ul className={searchDropdownStyles.searchDropdown}>
        {newsletterOptions.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              setIndex(item);
              setIsOpen(false);
              setAlgoliaIndex(item);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default SearchDropdown;
