import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import loadingStyles from "../../styles/Loading.module.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e23325",
    },
  },
});

const Loading = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className={loadingStyles.load}>
        <CircularProgress size="200px" thickness="3" color="primary" />
      </div>
    </ThemeProvider>
  );
};

export default Loading;
